@import url("https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap");

.homechanMessage {
  font-size: 25px;
}

.footer {
  text-align: center;
  height: 5vh;
}

.balloon {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(230, 164, 180);
  color: white;
}
/* 色付きの半円 */
.balloon::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  left: 20px;
  top: -15px;
  border-left: 20px solid #e6a4b4;
  border-top: 20px solid #e6a4b4;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
/* 白い半円 */
.balloon::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transform: rotate(45deg);
  left: 35px;
  top: -20px;
  border-left: 20px solid #fcfcf2;
  border-top: 20px solid #fcfcf2;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.MuiInputBase-input {
  background: white;
  border-radius: 10px;
}

.MuiTextField-root {
  background: white;
  border-radius: 10px;
}

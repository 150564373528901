@import url("https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.header {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  max-height: 10vh;
  margin-top: 3px;
  color: #e6a4b4;
}

.body {
  min-height: 80vh;
}

.footer {
  text-align: center;
  min-height: 5vh;
}
